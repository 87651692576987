
import Swiper from "swiper";
export default {
    data() {
        return {
            // bannerPar: [{
            //     title: 'Ex d IIC T4 Gb',
            //     dec: '防爆等级'
            // },
            // {
            //     title: 'IP67',
            //     dec: '防护等级'
            // },
            // {
            //     title: '0-1.5m/s',
            //     dec: '行驶速度'
            // },
            // {
            //     title: '≤30°',
            //     dec: '爬坡能力'
            // }],
            // bannerList: [{
            //     name: '防爆化工挂轨巡检机器人',
            //     dec: '提供表计识别读数、设备红外测温、跑冒滴漏检测、易燃有毒气体检测、粉尘环境监测、皮带异常监测等功能，适用于各类石油、化工、煤矿等易燃易爆、有毒有害的采集站、运输线、化工厂、存储区及井下的智能巡检业务场景。轨道系统依项目场地实际情况设计安装，机器人沿轨道行走，实现对项目场地待巡检对象的全面覆盖。'
            // },
            // {
            //     name: '应用场景',
            //     dec: '防爆化工挂轨巡检机器人适用于各类石油、化工、煤矿等易燃易爆、有毒有害的采集站、运输线、化工厂、存储区及井下的智能巡检业务场景。'
            // }],
            // funPar: [{
            //     name: '表计识别读数',
            //     dec: '通过AI视觉技术读取各类仪器仪表数据，采集到的数据超过阈值则产生预警。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/4_1p.jpg') : require('./img/mobile/4_1p.jpg')
            // },
            // {
            //     name: '跑冒滴漏监测',
            //     dec: '通过AI视觉识别技术等手段进行跑冒滴漏检测，能够显示泄漏位置的坐标并预警。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/4_2p.jpg') : require('./img/mobile/4_2p.jpg')
            // },
            // {
            //     name: '无人自主巡检',
            //     dec: '可实现自动巡检、手动巡检、异常巡检等巡检方式，沿着轨道自动巡检。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/5_1p.jpg') : require('./img/mobile/5_1p.jpg')
            // },
            // {
            //     name: '智能避障功能',
            //     dec: '若载避障传感器，通过激光雷达、防碰撞传感器实现安全运行、避障及预警。',
            //     img: this.$store.state.pageset.pcshow ? require('./img/5_2p.jpg') : require('./img/mobile/5_2p.jpg')
            // },
            // ],
            // // 轮播
            // swiperList: [{
            //     name: '设备红外测温',
            //     dec: '搭载红外热成像仪，能够对设备表面温度进行采集，对设备温度异常进行预警',
            //     img: this.$store.state.pageset.pcshow ? require('./img/6_1p.jpg') : require('./img/mobile/6_1p.jpg')
            // },
            // {
            //     name: '环境气体监测',
            //     dec: '可对氧气、一氧化碳、可燃性气体、硫化氢等易燃易爆、有毒有害气体进行实时监测',
            //     img: this.$store.state.pageset.pcshow ? require('./img/6_2p.jpg') : require('./img/mobile/6_2p.jpg')
            // }],
            // parList: {
            //     left: [{
            //         name: '外形尺寸 (长×宽×高)',
            //         value: `640mm×300mm
            //         ×460mm`
            //     }, {
            //         name: '防爆等级',
            //         value: 'Ex d IIB T4 Gb'
            //     }, {
            //         name: '工作环境温度',
            //         value: '-20°C~+60°C'
            //     }, {
            //         name: '爬坡能力',
            //         value: '≤30°'
            //     }, {
            //         name: '充电时间',
            //         value: '≤3小时'
            //     }, {
            //         name: '定位方式',
            //         value: '里程计+RFID校准'
            //     }],
            //     right: [
            //         {
            //             name: '整备质量',
            //             value: '≤80kg'
            //         },
            //         {
            //             name: '防护等级',
            //             value: 'IP67'
            //         },
            //         {
            //             name: '行走速度',
            //             value: `0-1.5m/s，
            //             速度可调`
            //         },
            //         {
            //             name: '刹车距离',
            //             value: '600mm'
            //         },
            //         {
            //             name: '续航时间',
            //             value: '≥6公里'
            //         },
            //         {
            //             name: '重复导航定位精度',
            //             value: '≤1cm'
            //         },
            //     ]
            // },
            elmentList:[],
            aboutswiper: null,
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper();
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        // 初始化轮播图
        aboutSwiper() {
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
            });
        },

        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}