import { render, staticRenderFns } from "./flameproofHanging.vue?vue&type=template&id=086f8533&scoped=true&"
import script from "./flameproofHanging.vue?vue&type=script&lang=js&"
export * from "./flameproofHanging.vue?vue&type=script&lang=js&"
import style0 from "./flameproofHanging.vue?vue&type=style&index=0&id=086f8533&prod&scoped=true&rel=stylesheet%2Fstylus&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086f8533",
  null
  
)

export default component.exports